var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "form-edit-component-large",
          attrs: { title: "日志", visible: _vm.dialogVisible },
          on: { cancel: _vm.dialogClose },
        },
        [
          _c("a-table", {
            staticClass: "m-l-12",
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: _vm.Pagination,
              scroll: _vm.tableScroll,
              "row-key": "id",
              size: _vm.TableSize,
            },
            on: { change: _vm.tableChange },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showDetail(record)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "jtl-button",
                { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("edge-sync-log-detail-dialog", { ref: "edgeSyncLogDetailDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }