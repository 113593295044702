import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat, dateFromNow } from '@common-src/filter';

export class EdgeEntityModel extends BaseEntityModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/edge`;
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    // @FormControl({
    //     label: '类型',
    //     type: FormControlType.SELECT,
    //     required: true
    // } as FormControlOptionModel)
    // type: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    online: boolean = undefined;
    onlineDesc: string = undefined;
    lastConnectTime: number = undefined;
    routingKey: string = undefined;
    secret: string = undefined;
    type: string = 'default';

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            // {
            //     title: '类型',
            //     dataIndex: 'type'
            // },
            {
                title: '状态',
                dataIndex: 'online',
                customRender: (text, record) => {
                    return record.online ? '在线' : '离线';
                }
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '最近活跃时间',
                dataIndex: 'lastConnectTime',
                width: 200,
                customRender: (text, record) => {
                    return dateFormat(record.lastConnectTime);
                }
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                width: 200
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getDeviceTypeTableColumns() {
        return [
            {
                title: '产品名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 200
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                width: 200
            },
            {
                title: '所在产品分组',
                dataIndex: 'groupName',
                width: 200
            },
            {
                title: '协议',
                dataIndex: 'protocol',
                width: 200
            },
            {
                title: '是否网关',
                dataIndex: 'DisplayGateway',
                width: 100
            },
            {
                title: '添加时间',
                dataIndex: 'createdTime',
                width: 200
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    static getDeviceTableColumns() {
        return [
            {
                title: '设备名称',
                dataIndex: 'displayName',
                width: 300,
                ellipsis: true
            },
            {
                title: '所属产品',
                dataIndex: 'typeName',
                width: 150,
                ellipsis: true
            },
            {
                title: '在线/激活',
                scopedSlots: { customRender: 'deviceStatus' },
                width: 120
            },
            {
                title: '故障状态',
                dataIndex: 'faultStatusDesc',
                width: 120,
                ellipsis: true
            },
            {
                title: '安装位置',
                dataIndex: 'positionName',
                width: 120,
                ellipsis: true
            },
            {
                title: '最后上线时间',
                dataIndex: 'lastActivityTime',
                customRender: (text, record, index) => {
                    if (text) {
                        return `${dateFormat(text)} (${dateFromNow(text)})`;
                    }
                    return '-';
                },
                width: 250
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 140
                // fixed: 'right'
            }
        ];
    }

    static getDriverTableColumns() {
        return [
            {
                title: '驱动名称',
                dataIndex: 'name',
                width: 200,
                ellipsis: true
            },
            {
                title: '驱动标识符',
                dataIndex: 'identifier',
                width: 150,
                ellipsis: true
            },
            {
                title: '分类',
                dataIndex: 'category',
                width: 120
            },
            {
                title: '最新版本',
                dataIndex: 'version',
                width: 120,
                ellipsis: true
            },
            {
                title: '版本时间',
                dataIndex: 'versionTime',
                width: 180,
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '描述',
                dataIndex: 'description',
                ellipsis: true
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 80
                // fixed: 'right'
            }
        ];
    }

    static getEdgeSyncLogTableColumns() {
        return [
            {
                title: '同步时间',
                dataIndex: 'createTime',
                width: 300,
                ellipsis: true,
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '同步数量',
                dataIndex: 'syncNum',
                width: 150,
                ellipsis: true
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 140
            }
        ];
    }

    static getEdgeSyncLogDetailTableColumns() {
        return [
            {
                title: '产品名称',
                dataIndex: 'name',
                width: 200
            },
            {
                title: '标识符',
                dataIndex: 'identifier',
                width: 200
            },
            {
                title: '驱动',
                dataIndex: 'driver',
                width: 200
            },
            {
                title: '是否网关',
                dataIndex: 'type',
                customRender: (text) => {
                    return text === 'DEVICE' ? '否' : '是';
                },
                width: 100
            },
            {
                title: '平台版本时间',
                dataIndex: 'cloudVersionTime',
                customRender: (text) => {
                    return dateFormat(text);
                },
                width: 200
            },
            {
                title: '同步状态',
                dataIndex: 'result',
                width: 180,
                customRender: (text) => {
                    return text ? '成功' : '失败';
                }
            }
        ];
    }

    static getEdgeSyncLogDetailDriverTableColumns() {
        return [
            {
                title: '驱动名称',
                dataIndex: 'entityName',
                width: 200,
                ellipsis: true
            },
            {
                title: '驱动标识符',
                dataIndex: 'identifier',
                width: 150,
                ellipsis: true
            },
            {
                title: '同步时间',
                dataIndex: 'createTime',
                width: 180,
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '同步状态',
                dataIndex: 'result',
                width: 180,
                customRender: (text) => {
                    return text ? '成功' : '失败';
                }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.onlineDesc = this.online ? '在线' : '离线';
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class EdgeQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    name: string = undefined;

    toService() {
        return { params: this.name };
        // return super.getParams();
    }
}

export class EdgeDeviceQueryModel extends QueryPageModel {
    deviceName: string = null;
    edgeId: string = null;

    toService() {
        return super.getParams();
    }
}

export class EdgeDriverQueryModel extends QueryPageModel {
    name: string = null;
    edgeId: string = null;
    synced: boolean = false;

    toService() {
        const data = super.getParams();
        data.params.synced = this.synced;
        return data;
    }
}

export class EdgeProductQueryModel extends QueryPageModel {
    name: string = null;
    edgeId: string = null;
    synced: boolean = true;
    groupIds: string = null;

    toService() {
        const data = super.getParams();
        data.params.synced = this.synced;
        return data;
    }
}

export class EdgeLogQueryModel extends QueryPageModel {
    name: string = null;
    edgeId: string = null;
    syncId: string = null;
    identifier: string = null;
    type: string = null;

    toService() {
        const data = super.getParams();
        data.params.type = this.type;
        return data;
    }
}

export class EdgeLogTypeQueryModel extends QueryPageModel {
    name: string = null;
    edgeId: string = null;
    syncId: string = null;
    identifier: string = null;
    type: string = null;

    toService() {
        const data = super.getParams();
        return data;
    }
}
