var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "form-edit-component-large",
          attrs: { title: _vm.modalTitle, visible: _vm.dialogVisible },
          on: { cancel: _vm.dialogClose },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "edge-add-device-component form-edit-component-large transform-dialog",
            },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.listLoading } },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "transform-header",
                      attrs: { type: "flex", align: "bottom" },
                    },
                    [
                      _c("a-col", { attrs: { span: 11 } }, [
                        _vm._v(
                          " 选择需要添加的" +
                            _vm._s(this.formatTypeName()) +
                            " "
                        ),
                      ]),
                      _c("a-col", { attrs: { span: 11, offset: 2 } }, [
                        _vm._v(" 已选"),
                        _c(
                          "a",
                          {
                            staticClass:
                              "transform-remove-all jtl-link pull-right",
                            on: { click: _vm.removeAllClick },
                          },
                          [_vm._v("全部删除")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        {
                          staticClass: "transform-column left-transform-column",
                          attrs: { span: 11 },
                        },
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { padding: "8px 16px" },
                              attrs: { type: "flex", align: "middle" },
                            },
                            [
                              _vm.type !== "driver"
                                ? _c(
                                    "a-col",
                                    { attrs: { span: 14 } },
                                    [
                                      _vm.type === "device"
                                        ? _c("jtl-select-group-component", {
                                            attrs: {
                                              "group-type":
                                                _vm.GroupEntityType.DEVICE,
                                              "has-child-id": true,
                                              placeholder: "请选择设备分组",
                                            },
                                            on: {
                                              change: _vm.searchClickHandler,
                                            },
                                            model: {
                                              value: _vm.queryModel.groupIds,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryModel,
                                                  "groupIds",
                                                  $$v
                                                )
                                              },
                                              expression: "queryModel.groupIds",
                                            },
                                          })
                                        : _vm.type === "product"
                                        ? _c("jtl-select-group-component", {
                                            attrs: {
                                              "group-type":
                                                _vm.GroupEntityType.DEVICE_TYPE,
                                              "has-child-id": true,
                                              placeholder: "请选择产品分组",
                                            },
                                            on: {
                                              change: _vm.searchClickHandler,
                                            },
                                            model: {
                                              value: _vm.queryModel.groupIds,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryModel,
                                                  "groupIds",
                                                  $$v
                                                )
                                              },
                                              expression: "queryModel.groupIds",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 9,
                                    offset: _vm.type === "driver" ? 0 : 1,
                                  },
                                },
                                [
                                  _c("a-input-search", {
                                    staticClass: "page-table-search__input",
                                    attrs: {
                                      placeholder:
                                        "请输入" +
                                        this.formatTypeName() +
                                        "名称",
                                      "allow-clear": "",
                                    },
                                    on: { change: _vm.searchClickHandler },
                                    model: {
                                      value: _vm.queryModel.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryModel, "name", $$v)
                                      },
                                      expression: "queryModel.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "ul",
                            { staticClass: "ul-list" },
                            [
                              _c(
                                "li",
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      model: {
                                        value: _vm.AllCheck,
                                        callback: function ($$v) {
                                          _vm.AllCheck = $$v
                                        },
                                        expression: "AllCheck",
                                      },
                                    },
                                    [_vm._v("全部")]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.listData, function (item, index) {
                                return _c(
                                  "li",
                                  { key: index },
                                  [
                                    _c(
                                      "jtl-tooltip-component",
                                      {
                                        staticClass:
                                          "transform-name text-ellipsis",
                                        attrs: {
                                          title: item.displayName || item.name,
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-checkbox",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.checkOnChange(item)
                                              },
                                            },
                                            model: {
                                              value: item.check,
                                              callback: function ($$v) {
                                                _vm.$set(item, "check", $$v)
                                              },
                                              expression: "item.check",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.displayName || item.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _vm.listData && _vm.listData.length < _vm.total
                                ? _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "jtl-link",
                                        on: {
                                          click: _vm.transformLoadMoreClick,
                                        },
                                      },
                                      [_vm._v("加载更多")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "transform-column-middle",
                          attrs: { span: 2 },
                        },
                        [
                          _c("jtl-icon", {
                            staticClass: "center",
                            attrs: { type: "transform" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          staticClass: "transform-column",
                          attrs: { span: 11 },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "ul-list" },
                            _vm._l(_vm.rightDataList, function (item, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "jtl-tooltip-component",
                                    {
                                      staticClass:
                                        "transform-name transform-name-right text-ellipsis",
                                      attrs: {
                                        title: item.displayName || item.name,
                                        placement: "right",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.displayName || item.name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("a-icon", {
                                    staticClass: "right-close-icon",
                                    staticStyle: { color: "#f40" },
                                    attrs: { type: "close-circle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeItemClick(item, index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "jtl-button",
                { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
                [_vm._v("取消")]
              ),
              _c(
                "jtl-button",
                {
                  attrs: {
                    type: "primary",
                    "click-prop": _vm.dialogOK.bind(this),
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }