import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { EdgeEntityModel, EdgeQueryModel } from '@/entity-model/edge-entity';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import { IResponseFilterData } from '@common-src/interface/reponse-data.interface';
import { DeviceEntityModel } from '@common-src/entity-model/device-entity';
import { DeviceDriverEntityModel } from '@common-src/entity-model/device-driver.entity';
import { post } from '@common-src/service/request';
import { UserStoreModule } from '@common-src/store/modules/user';

export default class EdgeService extends BaseCRUDQService<EdgeEntityModel, EdgeQueryModel> {
    constructor(baseUrl = EdgeEntityModel.baseUrl) {
        super(baseUrl, EdgeEntityModel);
    }

    static async getEdgeDeviceTypeList(filter?: any, page?: number, pageSize?: number): Promise<IResponseFilterData<DeviceTypeEntityModel>> {
        const url = `${EdgeEntityModel.baseUrl}/product/list`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<DeviceTypeEntityModel>>(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: DeviceTypeEntityModel }
        );
        return res;
    }

    static async getEdgeDeviceList(filter?: any, page?: number, pageSize?: number): Promise<IResponseFilterData<DeviceEntityModel>> {
        const url = `${EdgeEntityModel.baseUrl}/device/list`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = await http.request<IResponseFilterData<DeviceEntityModel>>(
            {
                url,
                method: 'POST',
                params
            },
            { modelType: DeviceEntityModel }
        );
        return res;
    }

    static async getEdgeDriverList(filter?: any, page?: number, pageSize?: number): Promise<IResponseFilterData<DeviceDriverEntityModel>> {
        const url = `${EdgeEntityModel.baseUrl}/driver/list`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = post(url, params);
        return res;
    }

    static async edgeDeviceTypeSync(edgeId: string): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/product/assign`;
        const params = { edgeId };
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            },
            { isTransformRequestResult: false }
        );
        return res;
    }

    static async edgeDeviceTypeForceSync(edgeId: string, productId: string): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/product/assign`;
        const params = { edgeId, entityIds: [productId], force: true };
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            },
            { isTransformRequestResult: false }
        );
        return res;
    }

    static async linkProduct(edgeId: string, entityIds: Array<string>): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/product/assign`;
        const params = { edgeId, entityIds: entityIds, force: true };
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params
            },
            { isTransformRequestResult: false }
        );
        return res;
    }

    static async linkDevice(edgeId: string, entityIds: Array<string>): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/device/assign`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params: {
                    edgeId,
                    entityIds
                }
            },
            { isTransformRequestResult: false }
        );
        return res;
    }

    static async unlinkDevice(edgeId: string, entityIds: Array<string>): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/device/unassign`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params: {
                    edgeId,
                    entityIds
                }
            }
        );
        return res;
    }

    static async linkDriver(edgeId: string, entityIds: Array<string>): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/driver/assign`;
        const res = await http.request<any>(
            {
                url,
                method: 'POST',
                params: {
                    edgeId,
                    entityIds,
                    force: true,
                    projectId: UserStoreModule.CurrentProjectId
                }
            },
            { isTransformRequestResult: false }
        );
        return res;
    }

    static async getEdgeLogList(filter?: any, page?: number, pageSize?: number): Promise<IResponseFilterData<any>> {
        const url = `${EdgeEntityModel.baseUrl}/sync/log/statistics`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = post(url, params);
        return res;
    }

    static async getEdgeProductLogList(filter?: any, page?: number, pageSize?: number): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/sync/log/product/list`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = post(url, params);
        return res;
    }

    static async getEdgDriverLogList(filter?: any, page?: number, pageSize?: number): Promise<any> {
        const url = `${EdgeEntityModel.baseUrl}/sync/log/driver/list`;
        const params = Object.assign({}, (filter as any)?.toService?.() || filter, { page, limit: pageSize });
        const res = post(url, params);
        return res;
    }
}
