













































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import AssetGroupService from '@common-src/service/asset-group';
import AssetService from '@common-src/service/asset';
import { FormControlType } from '@common-src/model/form-control';
import { GroupEntityType } from '@common-src/model/enum';
import DeviceService from '@common-src/service/device';
import { DeviceEntityModel, DeviceQueryModel } from '@common-src/entity-model/device-entity';
import FormComponent from '@common-src/mixins/form-component';
import EdgeService from '@/service/edge';
import { EdgeProductQueryModel, EdgeDriverQueryModel } from '@/entity-model/edge-entity';

@Component
export default class EdgeAddDeviceDialog extends Mixins(TransformComponent, TableComponent) {
    modelId: string = null;
    modalTitle: string = null;
    type: string = null;

    created() {
        this.debounceTransformGetList = _.debounce(this.transformGetList, 500);
        this.pageSize = null;
        this.isLoadMore = true;
        this.isAutoQuery = false;
        // 解决初始化时未initTable，导致queryModel不存在groupIds，控制台报错
        this.queryModel = new EdgeProductQueryModel();
    }

    dialogOpen(modelId: string, type:string) {
        this.type = type;
        this.modalTitle = `添加${this.formatTypeName()}`;
        this.modelId = modelId;
        this.dialogVisible = true;
        this.rightDataList = [];
        this.listData = [];

        switch (type) {
            case 'product': {
                const model = new EdgeProductQueryModel();
                model.edgeId = this.modelId;
                model.synced = false;
                this.initTable({ listFunc: EdgeService.getEdgeDeviceTypeList, queryModel: model });
                break;
            }
            case 'device': {
                const deviceQueryModel = new DeviceQueryModel();
                deviceQueryModel.forceRoot = false;
                deviceQueryModel.groupIds = null;
                deviceQueryModel.name = null;
                this.initTable({ listFunc: EdgeService.getEdgeDeviceList, queryModel: deviceQueryModel });
                break;
            }

            case 'driver': {
                const model = new EdgeDriverQueryModel();
                model.edgeId = this.modelId;
                model.synced = false;
                this.initTable({ listFunc: EdgeService.getEdgeDriverList, queryModel: model });
                break;
            }
        }

        this.getList();
    }

    formatTypeName(): string {
        switch (this.type) {
            case 'product':
                return '产品';
            case 'device':
                return '设备';
            case 'driver':
                return '驱动';
        }
    }

    dialogOK() {
        if (!this.rightDataList || this.rightDataList.length === 0) {
            this.showMessageWarning(`请选择添加的${this.formatTypeName()}`);
            return;
        }

        switch (this.type) {
            case 'product': {
                return EdgeService.linkProduct(this.modelId, _.map(this.rightDataList, item => item.id)).then((res) => {
                    if (res?.message) {
                        this.showMessageSuccess(res.message);
                    }
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }
            case 'device': {
                return EdgeService.linkDevice(this.modelId, _.map(this.rightDataList, item => item.id)).then((res) => {
                    if (res?.message) {
                        this.showMessageSuccess(res.message);
                    }
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }

            case 'driver': {
                return EdgeService.linkDriver(this.modelId, _.map(this.rightDataList, item => item.id)).then((res) => {
                    if (res?.message) {
                        this.showMessageSuccess(res.message);
                    }
                    this.$emit('dialogOK');
                    this.dialogClose();
                });
            }
        }
    }

    searchClickHandler() {
        this.rightDataList = [];
        this.page = 1;
        this.getList();
    }
}
