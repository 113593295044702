var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "device-type-detail-component table-component" },
        [
          _c(
            "div",
            { staticClass: "page-body page-body-bg-white" },
            [
              _c(
                "a-tabs",
                {
                  attrs: { type: "card", "default-active-key": "1" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "基础信息" } },
                    [
                      _c(
                        "a-descriptions",
                        {
                          staticClass: "tab-margin-16",
                          attrs: { bordered: "" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _c(
                                "jtl-button",
                                {
                                  staticClass: "description-title-button",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.editClick },
                                },
                                [_vm._v("编辑")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "名称", span: 1 } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mediaWidth",
                                  attrs: { title: _vm.model.name },
                                },
                                [_vm._v(_vm._s(_vm.model.name))]
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "类型", span: 1 } },
                            [_vm._v(" " + _vm._s(_vm.model.type) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "key", span: 1 } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mediaWidth",
                                  attrs: { title: _vm.model.routingKey },
                                },
                                [_vm._v(_vm._s(_vm.model.routingKey))]
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "secret", span: 1 } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mediaWidth",
                                  attrs: { title: _vm.model.secret },
                                },
                                [_vm._v(_vm._s(_vm.model.secret))]
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "创建时间", span: 1 } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mediaWidth",
                                  attrs: {
                                    title: _vm._f("dateFormat")(
                                      _vm.model.createdTime
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.model.createdTime
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "最近活跃时间", span: 1 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.model.lastConnectTime
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "当前状态", span: 1 } },
                            [_vm._v(" " + _vm._s(_vm.model.onlineDesc) + " ")]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "描述", span: 2 } },
                            [_vm._v(" " + _vm._s(_vm.model.remark) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "驱动同步" } },
                    [
                      _c("EdgeDeviceDriverListComponent", {
                        attrs: { "edge-id": _vm.modelId },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "3", attrs: { tab: "产品同步" } },
                    [
                      _c("EdgeDeviceTypeListComponent", {
                        attrs: { "edge-id": _vm.modelId },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "4", attrs: { tab: "设备同步" } },
                    [
                      _c("EdgeDeviceListComponent", {
                        attrs: { "edge-id": _vm.modelId },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }