




























































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { EdgeEntityModel, EdgeQueryModel } from '@/entity-model/edge-entity';
import EdgeService from '@/service/edge';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import EdgeDeviceTypeListComponent from './edge-device-type-list.vue';
import EdgeDeviceListComponent from './edge-device-list.vue';
import EdgeDeviceDriverListComponent from './edge-driver-list.vue';

const edgeService = new EdgeService();

@Component({
    components: {
        EdgeDeviceTypeListComponent,
        EdgeDeviceListComponent,
        EdgeDeviceDriverListComponent
    }
})
export default class EdgeDetailDrawer extends DrawerComponent {
    model: EdgeEntityModel = new EdgeEntityModel();
    tabIndex: string = '1';
    modelId: string = null;

    drawerShow(model: EdgeEntityModel) {
        this.drawerOpen(model.id, model.name);
        this.modelId = model.id;
        this.init();
    }

    init() {
        this.startFullScreenLoading();
        edgeService.retrieve(this.modelId)
            .then(res => {
                this.model = res;
            })
            .catch(error => {
                this.model = new EdgeEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<EdgeEntityModel>).dialogOpen(this.model, edgeService as any, ViewModeType.UPDATE);
    }
}
