
































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import FormComponent from '@common-src/mixins/form-component';
import EdgeService from '@/service/edge';
import { EdgeEntityModel, EdgeLogTypeQueryModel } from '@/entity-model/edge-entity';
import moment from 'moment';
import { dateFormat } from '@common-src/filter';

@Component
export default class EdgeSyncLogDetailDialog extends Mixins(TransformComponent, TableComponent) {
    modelId: string = null;
    syncTime: string = null;
    syncCount: number = 0;
    type: string = null;
    query: EdgeLogTypeQueryModel = new EdgeLogTypeQueryModel();

    dialogOpen(model: any, type: any) {
        this.dialogVisible = true;
        this.type = type;
        if (this.type === 'PRODUCT') {
            this.tableColumns = EdgeEntityModel.getEdgeSyncLogDetailTableColumns();
        } else {
            this.tableColumns = EdgeEntityModel.getEdgeSyncLogDetailDriverTableColumns();
        }
        this.syncTime = dateFormat(model?.createTime);
        this.syncCount = model.syncNum;
        this.query.syncId = model.id;
        this.query.identifier = model.identifier;
        this.init();
    }

    tableHandlerChange(pagination: any) {
        this.page = pagination.current;
        this.pageSize = pagination.pageSize;
        this.init();
    }

    init() {
        if (this.type === 'PRODUCT') {
            EdgeService.getEdgeProductLogList(this.query, this.page, this.pageSize).then(res => {
                this.listData = res.items;
                this.total = res.Total;
            });
        }

        if (this.type === 'DRIVER') {
            EdgeService.getEdgDriverLogList(this.query, this.page, this.pageSize).then(res => {
                this.listData = res.items;
                this.total = res.Total;
            });
        }
    }
}
