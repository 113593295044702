
































import { Component, Prop } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import { DeviceEntityModel } from '@common-src/entity-model/device-entity';
import EdgeService from '@/service/edge';
import { EdgeEntityModel, EdgeDriverQueryModel } from '@/entity-model/edge-entity';
import EdgeAddDeviceDialog from './add-device.dialog.vue';
import EdgeSyncLogDialog from './edge-sync-log-dialog.vue';
import DeviceDriverDetailDrawer from '@common-src/pages3/dashboard/device-driver/device-driver-detail-drawer.vue';
import { PropertyEntityType } from '@common-src/model/enum';
import CommonService from '@common-src/service/common';

@Component({ components: { EdgeAddDeviceDialog, EdgeSyncLogDialog, DeviceDriverDetailDrawer } })
export default class EdgeDeviceDriverListComponent extends TableComponent<DeviceEntityModel, any> {
    @Prop()
    edgeId: string;

    categoryData: any = [];
    manufacturerData: any = [];

    created() {
        const edgeDriverQueryModel = new EdgeDriverQueryModel();
        edgeDriverQueryModel.edgeId = this.edgeId;
        edgeDriverQueryModel.synced = true;
        this.initTable({
            listFunc: EdgeService.getEdgeDriverList,
            queryModel: edgeDriverQueryModel,
            tableColumns: EdgeEntityModel.getDriverTableColumns()
        });
        this.getList();
    }

    addDevice() {
        (this.$refs.edgeAddDeviceDialog as EdgeAddDeviceDialog).dialogOpen(this.edgeId, 'driver');
    }

    showLog() {
        (this.$refs.edgeSyncLogDialog as EdgeSyncLogDialog).dialogOpen(this.edgeId, 'DRIVER');
    }

    asyncDevice() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择需要同步的驱动');
            return;
        }

        return EdgeService.linkDriver(this.edgeId, this.selectedRowKeys).then(res => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            this.getList();
        });
    }

    detailClick(model: any) {
        (this.$refs.deviceDriverDetailDrawer as DeviceDriverDetailDrawer).drawerShow(model, this.categoryData, this.manufacturerData);
    }

    async initPropertyData() {
        this.categoryData = await CommonService.getPropertyOptions(PropertyEntityType.DEVICE_DRIVER_CATEGORY);
        this.manufacturerData = await CommonService.getPropertyOptions(PropertyEntityType.DEVICE_DRIVER_MANUFACTURER);
    }
}
