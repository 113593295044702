var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticClass: "form-edit-component-large",
          attrs: { title: "日志", visible: _vm.dialogVisible },
          on: { cancel: _vm.dialogClose },
        },
        [
          _c("div", { staticClass: "m-l-12" }, [
            _vm._v(
              " 同步" +
                _vm._s(_vm.type === "PRODUCT" ? "产品" : "驱动") +
                "时间：" +
                _vm._s(_vm.syncTime) +
                " "
            ),
          ]),
          _c("div", { staticClass: "m-l-12 m-t-4" }, [
            _vm._v(
              " 同步" +
                _vm._s(_vm.type === "PRODUCT" ? "产品" : "驱动") +
                "数：" +
                _vm._s(_vm.syncCount) +
                " "
            ),
          ]),
          _c("a-table", {
            staticClass: "m-l-12 m-t-12",
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: _vm.Pagination,
              scroll: _vm.tableScroll,
              "row-key": _vm.TableRowKey,
              size: _vm.TableSize,
            },
            on: { change: _vm.tableHandlerChange },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "jtl-button",
                { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }