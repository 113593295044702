var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edge-device-type-list-component table-component" },
    [
      _c(
        "div",
        { staticClass: "flex m-b-12" },
        [
          _c("a-input-search", {
            staticClass: "m-l-12",
            staticStyle: { width: "250px" },
            attrs: { placeholder: "请输入产品名称" },
            model: {
              value: _vm.queryModel.name,
              callback: function ($$v) {
                _vm.$set(_vm.queryModel, "name", $$v)
              },
              expression: "queryModel.name",
            },
          }),
          _c(
            "jtl-button",
            {
              staticClass: "m-l-12",
              attrs: { type: "primary", "click-prop": _vm.addProduct },
            },
            [_vm._v("添加产品")]
          ),
          _vm._v("  "),
          _c(
            "jtl-button",
            {
              staticClass: "m-l-12",
              attrs: { type: "primary", "click-prop": _vm.syncAllProduct },
            },
            [_vm._v("同步产品")]
          ),
          _c(
            "jtl-button",
            { staticClass: "m-l-12", attrs: { "click-prop": _vm.showLog } },
            [_vm._v("查看同步日志")]
          ),
        ],
        1
      ),
      _c("a-table", {
        staticClass: "m-l-12",
        attrs: {
          loading: _vm.listLoading,
          columns: _vm.tableColumns,
          "data-source": _vm.listData,
          pagination: _vm.Pagination,
          scroll: _vm.tableScroll,
          "row-key": _vm.TableRowKey,
          size: _vm.TableSize,
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            onSelect: _vm.onSelect,
          },
        },
        on: { change: _vm.tableChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.detailClick(record)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("EdgeAddDeviceDialog", {
        ref: "edgeAddDeviceDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("device-type-detail-drawer", { ref: "deviceTypeDetailDrawer" }),
      _c("edge-sync-log-dialog", { ref: "edgeSyncLogDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }