

































import { Component, Prop } from 'vue-property-decorator';
import DeviceTypeDetailDrawer from '@common-src/pages3/dashboard/device-type/device-type-detail-drawer.vue';
import TableComponent from '@common-src/mixins/table-component';
import { DeviceTypeEntityModel } from '@common-src/entity-model/device-type-entity';
import EdgeService from '@/service/edge';
import { EdgeEntityModel, EdgeProductQueryModel } from '@/entity-model/edge-entity';
import EdgeSyncLogDialog from './edge-sync-log-dialog.vue';
import EdgeAddDeviceDialog from './add-device.dialog.vue';

@Component({
    components: {
        'device-type-detail-drawer': DeviceTypeDetailDrawer,
        'edge-sync-log-dialog': EdgeSyncLogDialog,
        EdgeAddDeviceDialog
    }
})
export default class EdgeDeviceTypeListComponent extends TableComponent<DeviceTypeEntityModel, any> {
    @Prop()
    edgeId: string;

    searchName: string = undefined;
    created() {
        const edgeProductQueryModel = new EdgeProductQueryModel();
        edgeProductQueryModel.edgeId = this.edgeId;
        edgeProductQueryModel.synced = true;
        this.initTable({
            listFunc: EdgeService.getEdgeDeviceTypeList,
            queryModel: edgeProductQueryModel,
            tableColumns: EdgeEntityModel.getDeviceTypeTableColumns()
        });
        this.getList();
    }

    detailClick(model: DeviceTypeEntityModel) {
        (this.$refs.deviceTypeDetailDrawer as DeviceTypeDetailDrawer).drawerShow(model);
    }

    showLog() {
        (this.$refs.edgeSyncLogDialog as EdgeSyncLogDialog).dialogOpen(this.edgeId, 'PRODUCT');
    }

    addProduct() {
        (this.$refs.edgeAddDeviceDialog as EdgeAddDeviceDialog).dialogOpen(this.edgeId, 'product');
    }

    syncAllProduct() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择需要同步的产品');
            return;
        }
        return EdgeService.linkProduct(this.edgeId, this.getSelectedRowIds()).then(res => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            // this.getList();
        });
        // return EdgeService.edgeDeviceTypeSync(this.edgeId).then(res => {
        //     if (res?.message) {
        //         this.showMessageSuccess(res.message);
        //     }
        //     this.getList();
        // });
    }

    syncOneProduct(model: EdgeEntityModel) {
        this.startFullScreenLoading('正在同步...');
        return EdgeService.edgeDeviceTypeForceSync(this.edgeId, model.id).then(res => {
            if (res?.message) {
                this.showMessageSuccess(res.message);
            }
            // this.getList();
        }).finally(() => { this.stopFullScreenLoading(); });
    }
}
