
































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TableComponent from '@common-src/mixins/table-component';
import TransformComponent from '@common-src/mixins/transform-component';
import FormComponent from '@common-src/mixins/form-component';
import EdgeService from '@/service/edge';
import { EdgeEntityModel, EdgeLogQueryModel } from '@/entity-model/edge-entity';
import moment from 'moment';
import EdgeSyncLogDetailDialog from './edge-sync-log-detail-dialog.vue';

@Component({
    components: {
        'edge-sync-log-detail-dialog': EdgeSyncLogDetailDialog
    }
})
export default class EdgeSyncLogDialog extends Mixins(TransformComponent, TableComponent) {
    modelId: string = null;
    type: string = null;

    created() {
        this.initTable({
            listFunc: EdgeService.getEdgeLogList,
            queryModel: new EdgeLogQueryModel(),
            tableColumns: EdgeEntityModel.getEdgeSyncLogTableColumns()
        });
    }

    dialogOpen(modelId: string, type: string) {
        this.dialogVisible = true;
        this.queryModel.edgeId = modelId;
        this.queryModel.type = type;
        this.type = type;
        this.tableColumns = EdgeEntityModel.getEdgeSyncLogTableColumns();
        if (type === 'DEVICE') {
            this.tableColumns = this.tableColumns.slice(0, -1);
        }
        this.getList();
    }

    showDetail(record:any) {
        (this.$refs.edgeSyncLogDetailDialog as EdgeSyncLogDetailDialog).dialogOpen(record, this.type);
    }
}
